// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-automation-js": () => import("./../../../src/pages/automation.js" /* webpackChunkName: "component---src-pages-automation-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-collaboratebreakthesilos-js": () => import("./../../../src/pages/collaboratebreakthesilos.js" /* webpackChunkName: "component---src-pages-collaboratebreakthesilos-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-customerjourney-js": () => import("./../../../src/pages/customerjourney.js" /* webpackChunkName: "component---src-pages-customerjourney-js" */),
  "component---src-pages-d-2-c-js": () => import("./../../../src/pages/d2c.js" /* webpackChunkName: "component---src-pages-d-2-c-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledgehub-js": () => import("./../../../src/pages/knowledgehub.js" /* webpackChunkName: "component---src-pages-knowledgehub-js" */),
  "component---src-pages-leadsopportunitiesandclosures-js": () => import("./../../../src/pages/leadsopportunitiesandclosures.js" /* webpackChunkName: "component---src-pages-leadsopportunitiesandclosures-js" */),
  "component---src-pages-outoftheboxuse-js": () => import("./../../../src/pages/outoftheboxuse.js" /* webpackChunkName: "component---src-pages-outoftheboxuse-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-recruitpro-js": () => import("./../../../src/pages/recruitpro.js" /* webpackChunkName: "component---src-pages-recruitpro-js" */),
  "component---src-pages-securityandaccess-js": () => import("./../../../src/pages/securityandaccess.js" /* webpackChunkName: "component---src-pages-securityandaccess-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-staffing-js": () => import("./../../../src/pages/staffing.js" /* webpackChunkName: "component---src-pages-staffing-js" */),
  "component---src-pages-termscondition-js": () => import("./../../../src/pages/termscondition.js" /* webpackChunkName: "component---src-pages-termscondition-js" */),
  "component---src-pages-valueadds-js": () => import("./../../../src/pages/valueadds.js" /* webpackChunkName: "component---src-pages-valueadds-js" */)
}

